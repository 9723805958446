function tuiIsEdge(userAgent) {
    return userAgent.toLowerCase().includes('edge');
}

const EDGE = 'edge/';
function tuiIsEdgeOlderThan(version, userAgent) {
    const currentVersion = parseInt(userAgent.slice(userAgent.toLowerCase().indexOf(EDGE) + EDGE.length), 10);
    return currentVersion < version;
}

function tuiIsFirefox(userAgent) {
    return userAgent.toLowerCase().includes('firefox');
}

function tuiIsSafari({ ownerDocument: doc }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const win = doc === null || doc === void 0 ? void 0 : doc.defaultView;
    const isMacOsSafari = typeof win.safari !== 'undefined' &&
        ((_b = (_a = win.safari) === null || _a === void 0 ? void 0 : _a.pushNotification) === null || _b === void 0 ? void 0 : _b.toString()) === '[object SafariRemoteNotification]';
    const isIosSafari = !!((_d = (_c = win.navigator) === null || _c === void 0 ? void 0 : _c.vendor) === null || _d === void 0 ? void 0 : _d.includes('Apple')) &&
        !((_f = (_e = win.navigator) === null || _e === void 0 ? void 0 : _e.userAgent) === null || _f === void 0 ? void 0 : _f.includes('CriOS')) &&
        !((_h = (_g = win.navigator) === null || _g === void 0 ? void 0 : _g.userAgent) === null || _h === void 0 ? void 0 : _h.includes('FxiOS'));
    return isMacOsSafari || isIosSafari;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiIsEdge, tuiIsEdgeOlderThan, tuiIsFirefox, tuiIsSafari };

