import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class TuiMapperPipe {
  transform(value, mapper, ...args) {
    return mapper(value, ...args);
  }
}
TuiMapperPipe.ɵfac = function TuiMapperPipe_Factory(t) {
  return new (t || TuiMapperPipe)();
};
TuiMapperPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiMapper",
  type: TuiMapperPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMapperPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiMapper'
    }]
  }], null, null);
})();
class TuiMapperPipeModule {}
TuiMapperPipeModule.ɵfac = function TuiMapperPipeModule_Factory(t) {
  return new (t || TuiMapperPipeModule)();
};
TuiMapperPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiMapperPipeModule
});
TuiMapperPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMapperPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiMapperPipe],
      exports: [TuiMapperPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMapperPipe, TuiMapperPipeModule };
