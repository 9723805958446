import * as i0 from '@angular/core';
import { Directive, Self, Inject, SkipSelf, Optional } from '@angular/core';
import { TuiDestroyService, AbstractTuiControl } from '@taiga-ui/cdk';
import * as i1 from 'rxjs';
import { Observable, merge } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
class TuiDriver extends Observable {}
function tuiAsDriver(useExisting) {
  return {
    provide: TuiDriver,
    multi: true,
    useExisting
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class TuiVehicle {}
function tuiAsVehicle(useExisting) {
  return {
    provide: TuiVehicle,
    multi: true,
    useExisting
  };
}
class AbstractTuiDriverDirective {
  constructor(destroy$, drivers, vehicles) {
    this.destroy$ = destroy$;
    this.drivers = drivers;
    this.vehicles = vehicles;
  }
  ngOnInit() {
    const vehicle = this.vehicles.find(({
      type
    }) => type === this.type);
    merge(...this.drivers.filter(({
      type
    }) => type === this.type)).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(value => {
      vehicle === null || vehicle === void 0 ? void 0 : vehicle.toggle(value);
    });
  }
}
AbstractTuiDriverDirective.ɵfac = function AbstractTuiDriverDirective_Factory(t) {
  return new (t || AbstractTuiDriverDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TuiDriver), i0.ɵɵdirectiveInject(TuiVehicle));
};
AbstractTuiDriverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiDriverDirective
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiDriverDirective, [{
    type: Directive
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiDriver]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiVehicle]
      }]
    }];
  }, null);
})();
class AbstractTuiTextfieldHost {
  constructor(host) {
    this.host = host;
  }
  get readOnly() {
    return this.host.readOnly;
  }
  get disabled() {
    return this.host.computedDisabled;
  }
  get invalid() {
    return this.host.computedInvalid;
  }
  get focusable() {
    return this.host.computedFocusable;
  }
  get inputMode() {
    return 'text';
  }
  get value() {
    var _a;
    return ((_a = this.host.value) === null || _a === void 0 ? void 0 : _a.toString()) || '';
  }
  process(_input) {}
}
AbstractTuiTextfieldHost.ɵfac = function AbstractTuiTextfieldHost_Factory(t) {
  return new (t || AbstractTuiTextfieldHost)(i0.ɵɵdirectiveInject(AbstractTuiControl));
};
AbstractTuiTextfieldHost.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiTextfieldHost
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiTextfieldHost, [{
    type: Directive
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [AbstractTuiControl]
      }]
    }];
  }, null);
})();

// TODO: Drop any
function tuiFallbackAccessor(type) {
  return (accessors, fallback) => (accessors === null || accessors === void 0 ? void 0 : accessors.find(accessor => accessor !== fallback && accessor.type === type)) || fallback;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class TuiPositionAccessor {}
// TODO: Make fallback required
function tuiPositionAccessorFor(type, fallback) {
  return {
    provide: TuiPositionAccessor,
    deps: fallback ? [[new SkipSelf(), new Optional(), TuiPositionAccessor], fallback] : [[new SkipSelf(), new Optional(), TuiPositionAccessor]],
    useFactory: tuiFallbackAccessor(type)
  };
}
function tuiAsPositionAccessor(useExisting) {
  return {
    provide: TuiPositionAccessor,
    multi: true,
    useExisting
  };
}

// TODO: Rename to getBoundingClientRect to match the DOM API
// eslint-disable-next-line @typescript-eslint/naming-convention
class TuiRectAccessor {}
function tuiRectAccessorFor(type, fallback) {
  return {
    provide: TuiRectAccessor,
    deps: [[new SkipSelf(), new Optional(), TuiRectAccessor], fallback],
    useFactory: tuiFallbackAccessor(type)
  };
}
const tuiFallbackRectAccessor = tuiFallbackAccessor;
function tuiAsRectAccessor(useExisting) {
  return {
    provide: TuiRectAccessor,
    multi: true,
    useExisting
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiDriverDirective, AbstractTuiTextfieldHost, TuiDriver, TuiPositionAccessor, TuiRectAccessor, TuiVehicle, tuiAsDriver, tuiAsPositionAccessor, tuiAsRectAccessor, tuiAsVehicle, tuiFallbackAccessor, tuiFallbackRectAccessor, tuiPositionAccessorFor, tuiRectAccessorFor };
