import * as i0 from '@angular/core';
import { NgZone, Injectable, Inject, LOCALE_ID, ElementRef, Optional, Self, SecurityContext } from '@angular/core';
import * as i3 from '@taiga-ui/cdk';
import { tuiZoneOptimized, TUI_WINDOW_SIZE, tuiZonefree, EMPTY_CLIENT_RECT, TuiDestroyService, tuiAssert, TUI_IS_WEBKIT } from '@taiga-ui/cdk';
import { TUI_MEDIA, TUI_SANITIZER, TUI_ICONS } from '@taiga-ui/core/tokens';
import * as i1 from 'rxjs';
import { Observable, of, BehaviorSubject, fromEvent, merge, timer, EMPTY } from 'rxjs';
import { map, distinctUntilChanged, shareReplay, startWith, share, finalize, takeUntil } from 'rxjs/operators';
import { WINDOW, ANIMATION_FRAME } from '@ng-web-apis/common';
import * as i2 from '@taiga-ui/core/abstract';
import { TuiPositionAccessor } from '@taiga-ui/core/abstract';
import * as i1$1 from '@angular/router';
import { RouterLinkActive } from '@angular/router';
import * as i1$2 from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { tuiProcessIcon } from '@taiga-ui/core/utils/dom';

/**
 * Service to provide the current breakpoint based on Taiga UI's media queries
 */
class TuiBreakpointService extends Observable {
  constructor(media, ngZone, size$) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.media = media;
    this.ngZone = ngZone;
    this.size$ = size$;
    this.sorted = Object.values(this.media).sort((a, b) => a - b);
    this.invert = Object.keys(this.media).reduce((ret, key) => Object.assign(Object.assign({}, ret), {
      [this.media[key]]: key
    }), {});
    this.stream$ = this.size$.pipe(map(({
      width
    }) => this.sorted.find(size => size > width)), map(key => this.invert[key || this.sorted[this.sorted.length - 1]]), distinctUntilChanged(), tuiZoneOptimized(this.ngZone), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
}
TuiBreakpointService.ɵfac = function TuiBreakpointService_Factory(t) {
  return new (t || TuiBreakpointService)(i0.ɵɵinject(TUI_MEDIA), i0.ɵɵinject(NgZone), i0.ɵɵinject(TUI_WINDOW_SIZE));
};
TuiBreakpointService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiBreakpointService,
  factory: TuiBreakpointService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBreakpointService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_MEDIA]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_WINDOW_SIZE]
      }]
    }];
  }, null);
})();
class TuiFormatDateService {
  constructor(locale) {
    this.locale = locale;
  }
  format(timestamp) {
    return of(new Date(timestamp).toLocaleTimeString(this.locale, {
      hour: 'numeric',
      minute: '2-digit'
    }));
  }
}
TuiFormatDateService.ɵfac = function TuiFormatDateService_Factory(t) {
  return new (t || TuiFormatDateService)(i0.ɵɵinject(LOCALE_ID));
};
TuiFormatDateService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiFormatDateService,
  factory: TuiFormatDateService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFormatDateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [LOCALE_ID]
      }]
    }];
  }, null);
})();

/**
 * Service for displaying hints/tooltips
 */
class TuiHintService extends BehaviorSubject {
  constructor() {
    super([]);
  }
  add(directive) {
    this.next(this.value.concat(directive));
  }
  remove(directive) {
    if (this.value.includes(directive)) {
      this.next(this.value.filter(hint => hint !== directive));
    }
  }
}
TuiHintService.ɵfac = function TuiHintService_Factory(t) {
  return new (t || TuiHintService)();
};
TuiHintService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiHintService,
  factory: TuiHintService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class TuiNightThemeService extends Observable {
  constructor(win) {
    const media = win.matchMedia('(prefers-color-scheme: dark)');
    const media$ = fromEvent(media, 'change').pipe(startWith(null), map(() => media.matches), share());
    super(subscriber => media$.subscribe(subscriber));
  }
}
TuiNightThemeService.ɵfac = function TuiNightThemeService_Factory(t) {
  return new (t || TuiNightThemeService)(i0.ɵɵinject(WINDOW));
};
TuiNightThemeService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiNightThemeService,
  factory: TuiNightThemeService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNightThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }];
  }, null);
})();
class TuiPositionService extends Observable {
  constructor(
  // Destructuring here causes memory leak
  el, animationFrame, zone, accessor) {
    super(subscriber => animationFrame.pipe(map(() => el.nativeElement.getBoundingClientRect()), map(rect => accessor.getPosition(rect)), tuiZonefree(zone), finalize(() => accessor.getPosition(EMPTY_CLIENT_RECT))).subscribe(subscriber));
  }
}
TuiPositionService.ɵfac = function TuiPositionService_Factory(t) {
  return new (t || TuiPositionService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(ANIMATION_FRAME), i0.ɵɵinject(NgZone), i0.ɵɵinject(TuiPositionAccessor));
};
TuiPositionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiPositionService,
  factory: TuiPositionService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPositionService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i2.TuiPositionAccessor,
      decorators: [{
        type: Inject,
        args: [TuiPositionAccessor]
      }]
    }];
  }, null);
})();

// TODO: Remove when Angular is update and `RouterLinkActive` has output
class TuiRouterLinkActiveService extends Observable {
  constructor(routerLinkActive, zone, animationFrame$, destroy$) {
    const stream$ = routerLinkActive ? merge(timer(0),
    // SSR (animationFrame$ never emits value during SSR)
    animationFrame$).pipe(map(() => routerLinkActive.isActive), distinctUntilChanged(), tuiZoneOptimized(zone), takeUntil(destroy$)) : EMPTY;
    super(subscriber => stream$.subscribe(subscriber));
  }
}
TuiRouterLinkActiveService.ɵfac = function TuiRouterLinkActiveService_Factory(t) {
  return new (t || TuiRouterLinkActiveService)(i0.ɵɵinject(RouterLinkActive, 8), i0.ɵɵinject(NgZone), i0.ɵɵinject(ANIMATION_FRAME), i0.ɵɵinject(TuiDestroyService, 2));
};
TuiRouterLinkActiveService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiRouterLinkActiveService,
  factory: TuiRouterLinkActiveService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRouterLinkActiveService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1$1.RouterLinkActive,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [RouterLinkActive]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }, {
      type: i3.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, null);
})();

/**
 * Service for reusing SVGs without inlining each instance
 */
class TuiSvgService {
  constructor(tuiSanitizer, sanitizer, icons) {
    this.tuiSanitizer = tuiSanitizer;
    this.sanitizer = sanitizer;
    this.originals = {};
    this.items$ = new BehaviorSubject(new Map());
    this.define(icons);
  }
  define(icons) {
    const {
      value
    } = this.items$;
    Object.keys(icons).forEach(key => {
      this.defineIcon(key, icons[key], value);
    });
    this.items$.next(value);
  }
  getOriginal(name) {
    return this.originals[name] || null;
  }
  defineIcon(name, src, map) {
    if (map.has(name)) {
      return;
    }
    const parsed = this.parseSrc(name, src);
    if (!parsed) {
      ngDevMode && tuiAssert.assert(false, 'Unable to parse given SVG src');
      return;
    }
    map.set(name, parsed);
    this.originals = Object.assign(Object.assign({}, this.originals), {
      [name]: src
    });
  }
  parseSrc(name, src) {
    return this.sanitize(tuiProcessIcon(src, name));
  }
  sanitize(src) {
    return this.sanitizer.bypassSecurityTrustHtml((this.tuiSanitizer ? this.tuiSanitizer.sanitize(SecurityContext.HTML, src) : this.sanitizer.sanitize(SecurityContext.HTML, src)) || '');
  }
}
TuiSvgService.ɵfac = function TuiSvgService_Factory(t) {
  return new (t || TuiSvgService)(i0.ɵɵinject(TUI_SANITIZER, 8), i0.ɵɵinject(DomSanitizer), i0.ɵɵinject(TUI_ICONS));
};
TuiSvgService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiSvgService,
  factory: TuiSvgService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSvgService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.Sanitizer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_SANITIZER]
      }]
    }, {
      type: i1$2.DomSanitizer,
      decorators: [{
        type: Inject,
        args: [DomSanitizer]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ICONS]
      }]
    }];
  }, null);
})();
class TuiVisualViewportService {
  constructor(win, isWebkit) {
    this.win = win;
    this.isWebkit = isWebkit;
  }
  // https://bugs.webkit.org/show_bug.cgi?id=207089
  correct([y, x]) {
    return [this.correctY(y), this.correctX(x)];
  }
  correctX(x) {
    var _a, _b;
    return this.isWebkit ? x + ((_b = (_a = this.win.visualViewport) === null || _a === void 0 ? void 0 : _a.offsetLeft) !== null && _b !== void 0 ? _b : 0) : x;
  }
  correctY(y) {
    var _a, _b;
    return this.isWebkit ? y + ((_b = (_a = this.win.visualViewport) === null || _a === void 0 ? void 0 : _a.offsetTop) !== null && _b !== void 0 ? _b : 0) : y;
  }
}
TuiVisualViewportService.ɵfac = function TuiVisualViewportService_Factory(t) {
  return new (t || TuiVisualViewportService)(i0.ɵɵinject(WINDOW), i0.ɵɵinject(TUI_IS_WEBKIT));
};
TuiVisualViewportService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiVisualViewportService,
  factory: TuiVisualViewportService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiVisualViewportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_WEBKIT]
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiBreakpointService, TuiFormatDateService, TuiHintService, TuiNightThemeService, TuiPositionService, TuiRouterLinkActiveService, TuiSvgService, TuiVisualViewportService };
