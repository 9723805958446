import { inject, isDevMode, InjectionToken, NgZone } from '@angular/core';
import { tuiCreateTokenFromFactory, tuiCreateToken, tuiProvideOptions, TuiDayOfWeek, tuiTypedFromEvent, tuiZoneOptimized, TUI_SCROLL_REF as TUI_SCROLL_REF$1, tuiSvgLinearGradientProcessor } from '@taiga-ui/cdk';
import { DOCUMENT } from '@angular/common';
import { tuiExtractI18n } from '@taiga-ui/i18n';
import { tuiIconsPathFactory, tuiIsMobile } from '@taiga-ui/core/utils';
import { WINDOW } from '@ng-web-apis/common';
import { share, startWith, map, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { TUI_DEFAULT_NUMBER_FORMAT } from '@taiga-ui/core/constants';
import { of, merge, identity } from 'rxjs';
import { TuiAppearance } from '@taiga-ui/core/enums';

const TUI_REDUCED_MOTION = tuiCreateTokenFromFactory(() => {
    var _a, _b, _c;
    return (_c = (_b = (_a = inject(DOCUMENT).defaultView) === null || _a === void 0 ? void 0 : _a.matchMedia) === null || _b === void 0 ? void 0 : _b.call(_a, '(prefers-reduced-motion: reduce)').matches) !== null && _c !== void 0 ? _c : false;
});

const TUI_ANIMATIONS_DEFAULT_DURATION = 300;
/**
 * Duration of all Taiga UI animations in ms
 */
const TUI_ANIMATIONS_DURATION = tuiCreateTokenFromFactory(() => inject(TUI_REDUCED_MOTION) ? 0 : TUI_ANIMATIONS_DEFAULT_DURATION);

/**
 * Options for Taiga UI animations
 */
const TUI_ANIMATION_OPTIONS = tuiCreateTokenFromFactory(() => ({
    value: '',
    params: {
        duration: inject(TUI_ANIMATIONS_DURATION),
    },
}));

/**
 * @deprecated:
 * Flag to enable assertions across Taiga UI
 *
 * The current token no longer matters as
 * it will not be provided in production
 */
const TUI_ASSERT_ENABLED = tuiCreateTokenFromFactory(() => isDevMode());

const COMMON_ICONS = {
    check: 'tuiIconCheck',
    close: 'tuiIconClose',
    error: 'tuiIconAlertCircle',
    more: 'tuiIconChevronRight',
};
const TUI_COMMON_ICONS = tuiCreateToken(COMMON_ICONS);
function tuiCommonIconsProvider(icons) {
    return tuiProvideOptions(TUI_COMMON_ICONS, icons, COMMON_ICONS);
}

/**
 * Accessor for data-list options
 */
const TUI_DATA_LIST_ACCESSOR = new InjectionToken('[TUI_DATA_LIST_ACCESSOR]');
function tuiAsDataListAccessor(useExisting) {
    return {
        provide: TUI_DATA_LIST_ACCESSOR,
        useExisting,
    };
}

/**
 * DataList controller
 */
const TUI_DATA_LIST_HOST = new InjectionToken('[TUI_DATA_LIST_HOST]');
function tuiAsDataListHost(useExisting) {
    return {
        provide: TUI_DATA_LIST_HOST,
        useExisting,
    };
}

/**
 * Token for adding data-type attribute to calendar cell
 */
const TUI_DAY_TYPE_HANDLER = tuiCreateToken(day => day.isWeekend ? 'weekend' : 'weekday');

const TUI_DOCUMENT_OR_SHADOW_ROOT = new InjectionToken('[TUI_DOCUMENT_OR_SHADOW_ROOT]');

/**
 * ElementRef when you cannot use @Input for single time injection
 */
const TUI_ELEMENT_REF = new InjectionToken('[TUI_ELEMENT_REF]');

/**
 * The first day of the week index
 */
const TUI_FIRST_DAY_OF_WEEK = tuiCreateToken(TuiDayOfWeek.Monday);

/**
 * Localized months names
 */
const TUI_MONTHS = tuiCreateTokenFromFactory(tuiExtractI18n('months'));
/**
 * i18n 'close' word
 */
const TUI_CLOSE_WORD = tuiCreateTokenFromFactory(tuiExtractI18n('close'));
/**
 * i18n 'Nothing found' message
 */
const TUI_NOTHING_FOUND_MESSAGE = tuiCreateTokenFromFactory(tuiExtractI18n('nothingFoundMessage'));
/**
 * i18n of error message
 */
const TUI_DEFAULT_ERROR_MESSAGE = tuiCreateTokenFromFactory(tuiExtractI18n('defaultErrorMessage'));
/**
 * spin i18n texts
 */
const TUI_SPIN_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('spinTexts'));
/**
 * calendars i18n texts
 */
const TUI_SHORT_WEEK_DAYS = tuiCreateTokenFromFactory(tuiExtractI18n('shortWeekDays'));

const TUI_DEFAULT_ICONS_PLACE = 'assets/taiga-ui/icons';
/**
 * Path to icons
 * @deprecated Use {@link TUI_SVG_OPTIONS} instead
 */
const TUI_ICONS_PLACE = tuiCreateToken(TUI_DEFAULT_ICONS_PLACE);

/**
 * A key/value dictionary of icon names and src to be defined with TuiSvgService
 */
const TUI_ICONS = tuiCreateToken({});

/**
 * A handler to retrieve USE id for icon by name
 * @deprecated Use {@link TUI_SVG_OPTIONS} instead
 */
const TUI_ICONS_PATH = tuiCreateTokenFromFactory(() => tuiIconsPathFactory(inject(TUI_ICONS_PLACE)));

/**
 * Token for media constant
 */
const TUI_MEDIA = tuiCreateToken({
    mobile: 768,
    desktopSmall: 1024,
    desktopLarge: 1280,
});

/**
 * @deprecated use {@link https://taiga-ui.dev/services/breakpoint-service TuiBreakpointService}
 * TODO: drop in v4.0
 * Mobile resolution stream for private providers
 */
const TUI_IS_MOBILE_RES = tuiCreateTokenFromFactory(() => {
    const win = inject(WINDOW);
    const media = inject(TUI_MEDIA);
    return tuiTypedFromEvent(win, 'resize').pipe(share(), startWith(null), map(() => tuiIsMobile(win, media)), distinctUntilChanged(), tuiZoneOptimized(inject(NgZone)));
});

/**
 * @internal
 * @deprecated
 * Don't use it! Will be removed after changing the mask.
 */
const TUI_LEGACY_MASK = new InjectionToken('[TUI_LEGACY_MASK]');

/**
 * Mode stream for private providers
 */
const TUI_MODE = new InjectionToken('[TUI_MODE]');

/** @deprecated remove export */
const STATUS_ICON = {
    info: 'tuiIconInfo',
    success: 'tuiIconCheckCircle',
    error: 'tuiIconXCircle',
    warning: 'tuiIconAlertCircle',
    neutral: 'tuiIconInfo',
};
/** Default values for the notification options. */
const TUI_NOTIFICATION_DEFAULT_OPTIONS = {
    autoClose: 3000,
    label: '',
    status: 'info',
    hasCloseButton: true,
    icon: ({ $implicit }) => STATUS_ICON[$implicit],
    size: 'm',
    hasIcon: true,
    defaultAutoCloseTime: 3000,
};
/**
 * Default parameters for notification alert component
 */
const TUI_NOTIFICATION_OPTIONS = tuiCreateToken(TUI_NOTIFICATION_DEFAULT_OPTIONS);
function tuiNotificationOptionsProvider(options) {
    return tuiProvideOptions(TUI_NOTIFICATION_OPTIONS, options, TUI_NOTIFICATION_DEFAULT_OPTIONS);
}

/**
 * Formatting configuration for displayed numbers
 */
const TUI_NUMBER_FORMAT = tuiCreateToken(TUI_DEFAULT_NUMBER_FORMAT);
function tuiNumberFormatProvider(options) {
    return tuiProvideOptions(TUI_NUMBER_FORMAT, options, TUI_DEFAULT_NUMBER_FORMAT);
}

/**
 * Formatting configuration for displayed numbers
 */
const TUI_NUMBER_FORMAT_OBSERVABLE = tuiCreateToken(of(Object.assign(Object.assign({}, TUI_DEFAULT_NUMBER_FORMAT), { decimalLimit: NaN })));

/**
 * Content for tuiOption component
 */
const TUI_OPTION_CONTENT = new InjectionToken('[TUI_OPTION_CONTENT]');
function tuiAsOptionContent(useValue) {
    return {
        provide: TUI_OPTION_CONTENT,
        useValue,
    };
}

/**
 * TODO: v4.0 delete the whole file
 */
const convertToSundayFirstWeekFormat = (weekDaysNames) => {
    const sundayIndex = weekDaysNames.length - 1;
    return [
        weekDaysNames[sundayIndex],
        ...weekDaysNames.slice(0, sundayIndex),
    ];
};
/**
 * Ordered calendars i18n texts
 * @deprecated
 */
const TUI_ORDERED_SHORT_WEEK_DAYS = tuiCreateTokenFromFactory(() => {
    const firstDayOfWeekIndex = inject(TUI_FIRST_DAY_OF_WEEK);
    return inject(TUI_SHORT_WEEK_DAYS).pipe(map(convertToSundayFirstWeekFormat), map(weekDays => [
        ...weekDays.slice(firstDayOfWeekIndex),
        ...weekDays.slice(0, firstDayOfWeekIndex),
    ]));
});

/**
 * A custom Sanitizer to sanitize source before inlining
 */
const TUI_SANITIZER = new InjectionToken('[TUI_SANITIZER]');

/**
 * @deprecated import from `@taiga-ui/cdk` instead
 */
const TUI_SCROLL_REF = TUI_SCROLL_REF$1;

/**
 * A stream of possible selection changes
 */
const TUI_SELECTION_STREAM = tuiCreateTokenFromFactory(() => {
    const doc = inject(DOCUMENT);
    return merge(tuiTypedFromEvent(doc, 'selectionchange'), tuiTypedFromEvent(doc, 'mouseup'), tuiTypedFromEvent(doc, 'mousedown').pipe(switchMap(() => tuiTypedFromEvent(doc, 'mousemove').pipe(takeUntil(tuiTypedFromEvent(doc, 'mouseup'))))), tuiTypedFromEvent(doc, 'keydown'), tuiTypedFromEvent(doc, 'keyup')).pipe(share());
});

const TUI_SPIN_ICONS = tuiCreateToken({
    decrement: 'tuiIconChevronLeft',
    increment: 'tuiIconChevronRight',
});

/**
 * Transform function the contents of the loaded svg file
 * @deprecated Use {@link TUI_SVG_OPTIONS} instead
 */
const TUI_SVG_CONTENT_PROCESSOR = tuiCreateToken(tuiSvgLinearGradientProcessor);

/**
 * Source path processor for svg
 * @deprecated Use {@link TUI_SVG_OPTIONS} instead
 */
const TUI_SVG_SRC_PROCESSOR = tuiCreateToken(identity);

/**
 * Appearance for inputs
 * @deprecated use {@link TUI_TEXTFIELD_APPEARANCE_DIRECTIVE} instead
 * TODO: Remove in 4.0
 */
const TUI_TEXTFIELD_APPEARANCE = tuiCreateToken(TuiAppearance.Textfield);

/**
 * An interface to communicate with textfield based controls
 */
const TUI_TEXTFIELD_HOST = new InjectionToken('[TUI_TEXTFIELD_HOST]');
function tuiAsTextfieldHost(useExisting) {
    return {
        provide: TUI_TEXTFIELD_HOST,
        useExisting,
    };
}

const TUI_THEME = tuiCreateToken('Taiga');

/**
 * Buffer token to pass NG_VALUE_ACCESSOR to a different Injector
 */
const TUI_VALUE_ACCESSOR = new InjectionToken('[TUI_VALUE_ACCESSOR]');

/**
 * Viewport accessor
 */
const TUI_VIEWPORT = tuiCreateTokenFromFactory(() => {
    const win = inject(WINDOW);
    return {
        type: 'viewport',
        getClientRect() {
            return {
                top: 0,
                left: 0,
                right: win.innerWidth,
                bottom: win.innerHeight,
                width: win.innerWidth,
                height: win.innerHeight,
            };
        },
    };
});
function tuiAsViewport(useExisting) {
    return {
        provide: TUI_VIEWPORT,
        useExisting,
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { STATUS_ICON, TUI_ANIMATIONS_DEFAULT_DURATION, TUI_ANIMATIONS_DURATION, TUI_ANIMATION_OPTIONS, TUI_ASSERT_ENABLED, TUI_CLOSE_WORD, TUI_COMMON_ICONS, TUI_DATA_LIST_ACCESSOR, TUI_DATA_LIST_HOST, TUI_DAY_TYPE_HANDLER, TUI_DEFAULT_ERROR_MESSAGE, TUI_DEFAULT_ICONS_PLACE, TUI_DOCUMENT_OR_SHADOW_ROOT, TUI_ELEMENT_REF, TUI_FIRST_DAY_OF_WEEK, TUI_ICONS, TUI_ICONS_PATH, TUI_ICONS_PLACE, TUI_IS_MOBILE_RES, TUI_LEGACY_MASK, TUI_MEDIA, TUI_MODE, TUI_MONTHS, TUI_NOTHING_FOUND_MESSAGE, TUI_NOTIFICATION_DEFAULT_OPTIONS, TUI_NOTIFICATION_OPTIONS, TUI_NUMBER_FORMAT, TUI_NUMBER_FORMAT_OBSERVABLE, TUI_OPTION_CONTENT, TUI_ORDERED_SHORT_WEEK_DAYS, TUI_REDUCED_MOTION, TUI_SANITIZER, TUI_SCROLL_REF, TUI_SELECTION_STREAM, TUI_SHORT_WEEK_DAYS, TUI_SPIN_ICONS, TUI_SPIN_TEXTS, TUI_SVG_CONTENT_PROCESSOR, TUI_SVG_SRC_PROCESSOR, TUI_TEXTFIELD_APPEARANCE, TUI_TEXTFIELD_HOST, TUI_THEME, TUI_VALUE_ACCESSOR, TUI_VIEWPORT, tuiAsDataListAccessor, tuiAsDataListHost, tuiAsOptionContent, tuiAsTextfieldHost, tuiAsViewport, tuiCommonIconsProvider, tuiNotificationOptionsProvider, tuiNumberFormatProvider };

