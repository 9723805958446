import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, inject, NgModule, Inject } from '@angular/core';
import { EMPTY, concat, defer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
function dasharize(camel) {
  return camel.replace(/[a-z][A-Z]/g, letterLetter => {
    return `${letterLetter[0]}-${letterLetter[1].toLowerCase()}`;
  });
}
class AbstractEventPlugin {
  supports(event) {
    return event.includes(this.modifier);
  }
  /** This is not used in Ivy anymore */
  addGlobalEventListener() {
    return () => {};
  }
  unwrap(event) {
    return event.split('.').filter(v => !this.modifier.includes(v)).join('.');
  }
}
class BindEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '$';
  }
  addEventListener(element, event) {
    element[event] = element[event] || EMPTY;
    const method = this.getMethod(element, event);
    const zone$ = this.manager.getZone().onStable;
    const sub = concat(zone$.pipe(takeWhile(() => element[event] === EMPTY)), defer(() => element[event])).subscribe(method);
    return () => sub.unsubscribe();
  }
  getMethod(element, event) {
    const [, key, value, unit = ''] = event.split('.');
    if (event.endsWith('.attr')) {
      return v => v === null ? element.removeAttribute(key) : element.setAttribute(key, String(v));
    }
    if (key === 'class') {
      return v => element.classList.toggle(value, !!v);
    }
    if (key === 'style') {
      return v => element.style.setProperty(dasharize(value), `${v}${unit}`);
    }
    return v => element[key] = v;
  }
}
/** @nocollapse */
BindEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵBindEventPlugin_BaseFactory;
  return function BindEventPlugin_Factory(t) {
    return (ɵBindEventPlugin_BaseFactory || (ɵBindEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(BindEventPlugin)))(t || BindEventPlugin);
  };
})();
/** @nocollapse */
BindEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BindEventPlugin,
  factory: BindEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BindEventPlugin, [{
    type: Injectable
  }], null, null);
})();
const GLOBAL_HANDLER = new InjectionToken('[GLOBAL_HANDLER]: Global event target handler', {
  factory: () => {
    const document = inject(DOCUMENT);
    return name => name === 'body' ? document.body : document.defaultView[name] || document.createElement('div');
  }
});
class GlobalEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.handler = inject(GLOBAL_HANDLER);
    this.modifier = '>';
  }
  addEventListener(_, event, handler) {
    return this.manager.addEventListener(this.handler(event.split('>')[0]), event.split('>')[1], handler);
  }
}
/** @nocollapse */
GlobalEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵGlobalEventPlugin_BaseFactory;
  return function GlobalEventPlugin_Factory(t) {
    return (ɵGlobalEventPlugin_BaseFactory || (ɵGlobalEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(GlobalEventPlugin)))(t || GlobalEventPlugin);
  };
})();
/** @nocollapse */
GlobalEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GlobalEventPlugin,
  factory: GlobalEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GlobalEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class OptionsEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = 'capture.once.passive';
  }
  supports(event) {
    return event.includes('.') && !this.unwrap(event).includes('.');
  }
  addEventListener(element, event, handler) {
    element.addEventListener(this.unwrap(event), handler, {
      once: event.includes('.once'),
      passive: event.includes('.passive'),
      capture: event.includes('.capture')
    });
    return () => element.removeEventListener(this.unwrap(event), handler, event.includes('.capture'));
  }
}
/** @nocollapse */
OptionsEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵOptionsEventPlugin_BaseFactory;
  return function OptionsEventPlugin_Factory(t) {
    return (ɵOptionsEventPlugin_BaseFactory || (ɵOptionsEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(OptionsEventPlugin)))(t || OptionsEventPlugin);
  };
})();
/** @nocollapse */
OptionsEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: OptionsEventPlugin,
  factory: OptionsEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OptionsEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class PreventEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.prevent';
  }
  addEventListener(element, event, handler) {
    const wrapped = event => {
      event.preventDefault();
      handler(event);
    };
    return this.manager.addEventListener(element, this.unwrap(event), wrapped);
  }
}
/** @nocollapse */
PreventEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵPreventEventPlugin_BaseFactory;
  return function PreventEventPlugin_Factory(t) {
    return (ɵPreventEventPlugin_BaseFactory || (ɵPreventEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(PreventEventPlugin)))(t || PreventEventPlugin);
  };
})();
/** @nocollapse */
PreventEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PreventEventPlugin,
  factory: PreventEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PreventEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class SelfEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.self';
  }
  addEventListener(element, event, handler) {
    const wrapped = event => {
      if (event.target === event.currentTarget) {
        handler(event);
      }
    };
    return this.manager.addEventListener(element, this.unwrap(event), wrapped);
  }
}
/** @nocollapse */
SelfEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵSelfEventPlugin_BaseFactory;
  return function SelfEventPlugin_Factory(t) {
    return (ɵSelfEventPlugin_BaseFactory || (ɵSelfEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(SelfEventPlugin)))(t || SelfEventPlugin);
  };
})();
/** @nocollapse */
SelfEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SelfEventPlugin,
  factory: SelfEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelfEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class SilentEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.silent';
  }
  addEventListener(element, event, handler) {
    SilentEventPlugin.ngZone = this.manager.getZone();
    return SilentEventPlugin.ngZone.runOutsideAngular(() => this.manager.addEventListener(element, this.unwrap(event), handler));
  }
}
/** @nocollapse */
SilentEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵSilentEventPlugin_BaseFactory;
  return function SilentEventPlugin_Factory(t) {
    return (ɵSilentEventPlugin_BaseFactory || (ɵSilentEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(SilentEventPlugin)))(t || SilentEventPlugin);
  };
})();
/** @nocollapse */
SilentEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SilentEventPlugin,
  factory: SilentEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SilentEventPlugin, [{
    type: Injectable
  }], null, null);
})();
class StopEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.stop';
  }
  addEventListener(element, event, handler) {
    const wrapped = event => {
      event.stopPropagation();
      handler(event);
    };
    return this.manager.addEventListener(element, this.unwrap(event), wrapped);
  }
}
/** @nocollapse */
StopEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵStopEventPlugin_BaseFactory;
  return function StopEventPlugin_Factory(t) {
    return (ɵStopEventPlugin_BaseFactory || (ɵStopEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(StopEventPlugin)))(t || StopEventPlugin);
  };
})();
/** @nocollapse */
StopEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StopEventPlugin,
  factory: StopEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StopEventPlugin, [{
    type: Injectable
  }], null, null);
})();

/**
 * TODO: Remove in v4.0.0
 * @deprecated
 */
class ZoneEventPlugin extends AbstractEventPlugin {
  constructor() {
    super(...arguments);
    this.modifier = '.init';
  }
  addEventListener() {
    console.warn('.init plugin is no longer necessary as of v3.1.0');
    return () => {};
  }
}
/** @nocollapse */
ZoneEventPlugin.ɵfac = /* @__PURE__ */(() => {
  let ɵZoneEventPlugin_BaseFactory;
  return function ZoneEventPlugin_Factory(t) {
    return (ɵZoneEventPlugin_BaseFactory || (ɵZoneEventPlugin_BaseFactory = i0.ɵɵgetInheritedFactory(ZoneEventPlugin)))(t || ZoneEventPlugin);
  };
})();
/** @nocollapse */
ZoneEventPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ZoneEventPlugin,
  factory: ZoneEventPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZoneEventPlugin, [{
    type: Injectable
  }], null, null);
})();
const PLUGINS = [SilentEventPlugin, BindEventPlugin, GlobalEventPlugin, OptionsEventPlugin, PreventEventPlugin, SelfEventPlugin, StopEventPlugin, ZoneEventPlugin];
const NG_EVENT_PLUGINS = PLUGINS.map(useClass => ({
  provide: EVENT_MANAGER_PLUGINS,
  multi: true,
  useClass
}));
function shouldCall(predicate) {
  return (_target, _key, desc) => {
    const {
      value
    } = desc;
    desc.value = function (...args) {
      if (!predicate.apply(this, args)) {
        return;
      }
      if (SilentEventPlugin.ngZone) {
        SilentEventPlugin.ngZone.run(() => value.apply(this, args));
      } else {
        value.apply(this, args);
      }
    };
  };
}
function asCallable(a) {
  return a;
}
class EventPluginsModule {
  constructor([plugin]) {
    console.assert(!(plugin instanceof SilentEventPlugin) || EventPluginsModule.initialized, 'EventPluginsModule must come after BrowserModule in imports');
    EventPluginsModule.initialized = true;
  }
}
EventPluginsModule.initialized = false;
/** @nocollapse */
EventPluginsModule.ɵfac = function EventPluginsModule_Factory(t) {
  return new (t || EventPluginsModule)(i0.ɵɵinject(EVENT_MANAGER_PLUGINS));
};
/** @nocollapse */
EventPluginsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: EventPluginsModule
});
/** @nocollapse */
EventPluginsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: NG_EVENT_PLUGINS
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventPluginsModule, [{
    type: NgModule,
    args: [{
      providers: NG_EVENT_PLUGINS
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [EVENT_MANAGER_PLUGINS]
      }]
    }];
  }, null);
})();

/**
 * Public API Surface of @tinkoff/ng-event-plugins
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BindEventPlugin, EventPluginsModule, GLOBAL_HANDLER, GlobalEventPlugin, NG_EVENT_PLUGINS, OptionsEventPlugin, PreventEventPlugin, SilentEventPlugin, StopEventPlugin, ZoneEventPlugin, asCallable, shouldCall };
