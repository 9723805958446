import { tuiAssert } from '@taiga-ui/cdk/classes';

/**
 * Adds 'px' to the number and turns it into a string
 */
function tuiPx(value) {
    ngDevMode && tuiAssert.assert(Number.isFinite(value), 'Value must be finite number');
    return `${value}px`;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiPx };

