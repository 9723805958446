import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { NgZone, ElementRef, Directive, Inject, Optional, SkipSelf, Input, Output, NgModule } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk/decorators';
import { tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { TUI_ACTIVE_ELEMENT } from '@taiga-ui/cdk/tokens';
import { tuiArrayRemove } from '@taiga-ui/cdk/utils';
import { map, startWith, distinctUntilChanged, skip } from 'rxjs/operators';
import * as i1 from 'rxjs';
class TuiActiveZoneDirective {
  constructor(active$, zone, el, directParentActiveZone) {
    var _a;
    this.active$ = active$;
    this.zone = zone;
    this.el = el;
    this.directParentActiveZone = directParentActiveZone;
    this.subActiveZones = [];
    this.tuiActiveZoneParent = null;
    this.tuiActiveZoneChange = this.active$.pipe(map(element => !!element && this.contains(element)), startWith(false), distinctUntilChanged(), skip(1), tuiZoneOptimized(this.zone));
    (_a = this.directParentActiveZone) === null || _a === void 0 ? void 0 : _a.addSubActiveZone(this);
  }
  set tuiActiveZoneParentSetter(zone) {
    this.setZone(zone);
  }
  ngOnDestroy() {
    var _a, _b;
    (_a = this.directParentActiveZone) === null || _a === void 0 ? void 0 : _a.removeSubActiveZone(this);
    (_b = this.tuiActiveZoneParent) === null || _b === void 0 ? void 0 : _b.removeSubActiveZone(this);
  }
  contains(node) {
    return this.el.nativeElement.contains(node) || this.subActiveZones.some((item, index, array) => array.indexOf(item) === index && item.contains(node));
  }
  setZone(zone) {
    var _a;
    (_a = this.tuiActiveZoneParent) === null || _a === void 0 ? void 0 : _a.removeSubActiveZone(this);
    zone === null || zone === void 0 ? void 0 : zone.addSubActiveZone(this);
    this.tuiActiveZoneParent = zone;
  }
  addSubActiveZone(activeZone) {
    this.subActiveZones = [...this.subActiveZones, activeZone];
  }
  removeSubActiveZone(activeZone) {
    this.subActiveZones = tuiArrayRemove(this.subActiveZones, this.subActiveZones.indexOf(activeZone));
  }
}
TuiActiveZoneDirective.ɵfac = function TuiActiveZoneDirective_Factory(t) {
  return new (t || TuiActiveZoneDirective)(i0.ɵɵdirectiveInject(TUI_ACTIVE_ELEMENT), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiActiveZoneDirective, 12));
};
TuiActiveZoneDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiActiveZoneDirective,
  selectors: [["", "tuiActiveZone", "", 5, "ng-container"], ["", "tuiActiveZoneChange", "", 5, "ng-container"], ["", "tuiActiveZoneParent", "", 5, "ng-container"]],
  hostBindings: function TuiActiveZoneDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("mousedown.silent", function TuiActiveZoneDirective_mousedown_silent_HostBindingHandler() {
        return 0;
      }, false, i0.ɵɵresolveDocument);
    }
  },
  inputs: {
    tuiActiveZoneParentSetter: [i0.ɵɵInputFlags.None, "tuiActiveZoneParent", "tuiActiveZoneParentSetter"]
  },
  outputs: {
    tuiActiveZoneChange: "tuiActiveZoneChange"
  },
  exportAs: ["tuiActiveZone"]
});
__decorate([tuiPure], TuiActiveZoneDirective.prototype, "setZone", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiActiveZoneDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiActiveZone]:not(ng-container), [tuiActiveZoneChange]:not(ng-container), [tuiActiveZoneParent]:not(ng-container)',
      exportAs: 'tuiActiveZone',
      host: {
        '(document:mousedown.silent)': '(0)'
      }
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_ACTIVE_ELEMENT]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: TuiActiveZoneDirective,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }, {
        type: Inject,
        args: [TuiActiveZoneDirective]
      }]
    }];
  }, {
    tuiActiveZoneParentSetter: [{
      type: Input,
      args: ['tuiActiveZoneParent']
    }],
    tuiActiveZoneChange: [{
      type: Output
    }],
    setZone: []
  });
})();
class TuiActiveZoneModule {}
TuiActiveZoneModule.ɵfac = function TuiActiveZoneModule_Factory(t) {
  return new (t || TuiActiveZoneModule)();
};
TuiActiveZoneModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiActiveZoneModule
});
TuiActiveZoneModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiActiveZoneModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiActiveZoneDirective],
      exports: [TuiActiveZoneDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiActiveZoneDirective, TuiActiveZoneModule };
